html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

.browserupgrade {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

body {
  background-image: linear-gradient(#f4f4f4, #ffffff);
  background-attachment: fixed;
  font-family: 'Open Sans', sans-serif;
  color: #333;
  margin: 0;
  @include breakpoint(mobile) {
    font-size: 0.9em;
  }
  &.legal {
    background: #fff;
  }
  &.beta {
    background: #1a2249;
    .logo {
      width: 180px;
      top: 50%;
      left: 50%;
      padding: 30px;
      transform: translate(-50%, -50%);
    }
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #1a2249;
  font-family: 'Gilroy', sans-serif;
}

article {
  max-width: 1200px;
  padding: 30px 15px;
  width: 100%;
  margin: 0 auto;

  main {
    padding: 0 15px;
    & > ol {
      padding: 0 0 0 15px;
    }
  }

  table {
    text-align: justify;
    border: 1px solid #9094a6;
    padding: 5px;
    font-family: 'Gilroy', sans-serif;
    margin: 15px 0;
  }

  a {
    color: #33b1ff;
    overflow-wrap: break-word;
    word-wrap: break-word;

    word-break: break-all;
    word-break: break-word;
    hyphens: auto;
  }

  @include breakpoint(mobile) {
    ol {
      padding: 0;
      & > li {
        padding: 0;
      }
    }
  }

  > header {
    margin: 0 15px 20px;
    svg,
    .logo {
      margin: 0 10px 0 -5px;
      position: relative;
      top: 2px;
      width: 30px;
    }
    hgroup {
      display: flex;
      align-items: baseline;
      padding: 5px 0;

      h1 {
        margin: 0;
        font-size: 18px;
        font-weight: 600;
      }

      .updated {
        text-align: right;
        flex: 1;
        align-items: center;
        font-size: 12px;
        margin: 0;
        color: #1a2249;
        font-family: 'Gilroy', sans-serif;
        @include breakpoint(mobile) {
          font-size: 0.2em;
        }
      }
    }
    border-bottom: 1px solid #9094a6;
  }
}

.logo {
  max-width: 100px;
}

.wrapper {
  max-width: 1200px;
  padding: 0 20px;
  margin: 0 auto;
}

.header-landing {
  padding: 40px 0 0 0;
  .logo {
    max-width: 140px;

    @include breakpoint(mobile) {
      max-width: 100px;
    }

    @include breakpoint(tablet) {
      max-width: 100px;
    }
    @include breakpoint(tablet-landscape) {
      max-width: 140px;
    }
  }
  @include breakpoint(mobile) {
    text-align: center;
    padding: 40px 0 20px;
  }
}

.content {
  .connect {
    display: flex;
    align-items: center;
    justify-content: center;

    hgroup {
      flex: 1;
    }
    .devices {
      flex: 1;
      text-align: right;
      @include breakpoint(mobile) {
        display: none;
        text-align: left;
      }
      img {
        width: 100%;
        max-width: 450px;
      }
    }
  }

  .stores {
    display: flex;
    align-items: center;
    img {
      display: block;
      height: 40px;
      margin: 0 10px 0 0;

      @include breakpoint(mobile) {
        height: auto;
        width: 200px;
        margin: 0 0 20px 0;
      }
    }
    @include breakpoint(mobile) {
      flex-direction: column;
      text-align: center;
    }
  }

  h1 {
    font-family: 'Gilroy', sans-serif;
    font-weight: bold;
    color: #1a2249;
    font-size: 80px;
    line-height: 80px;
    letter-spacing: 0.02em;
    margin: 0;
    padding: 0 0 20px 0;

    @include breakpoint(mobile) {
      font-size: 40px;
      line-height: 55px;
      text-align: center;
    }

    @include breakpoint(tablet) {
      font-size: 40px;
      line-height: 40px;
    }

    @include breakpoint(tablet-landscape) {
      font-size: 60px;
      line-height: 60px;
    }
  }

  h3 {
    font-family: 'Gilroy', sans-serif;
    font-weight: normal;
    font-size: 23px;
    letter-spacing: 0.02em;
    margin: 0;
    padding: 0 0 35px 0;
    color: #000;

    @include breakpoint(mobile) {
      font-size: 18px;
      text-align: center;
      padding: 0 0 50px 0;
    }

    @include breakpoint(tablet) {
      font-size: 18px;
      padding: 0 0 50px 0;
    }

    @include breakpoint(tablet-landscape) {
      font-size: 22px;
    }
  }

  h4 {
    font-family: 'Gilroy', sans-serif;
    font-weight: 600;
    font-size: 25px;
    color: #000;
    margin: 0;
    padding: 0 0 10px 0;
    text-transform: uppercase;

    @include breakpoint(mobile) {
      font-size: 18px;
      text-align: center;
      padding: 0 0 20px 0;
    }

    @include breakpoint(tablet) {
      font-size: 18px;
    }
  }
}


@keyframes closeWindow {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.ril__outer {
  background-color: rgba(0, 0, 0, 0.85);
  outline: none;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  -ms-content-zooming: none;
  -ms-user-select: none;
  -ms-touch-select: none;
  touch-action: none;
}

.ril__outerClosing {
  opacity: 0;
}

.ril__inner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.ril__image,
.ril__imagePrev,
.ril__imageNext {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  max-width: none;
  -ms-content-zooming: none;
  -ms-user-select: none;
  -ms-touch-select: none;
  touch-action: none;
}

.ril__imageDiscourager {
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.ril__navButtons {
  border: none;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 20px;
  height: 34px;
  padding: 40px 30px;
  margin: auto;
  cursor: pointer;
  opacity: 0.7;
}
.ril__navButtons:hover {
  opacity: 1;
}
.ril__navButtons:active {
  opacity: 0.7;
}

.ril__navButtonPrev {
  left: 0;
  background: rgba(0, 0, 0, 0.2)
    url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgd2lkdGg9IjIwIiBoZWlnaHQ9IjM0Ij48cGF0aCBkPSJtIDE5LDMgLTIsLTIgLTE2LDE2IDE2LDE2IDEsLTEgLTE1LC0xNSAxNSwtMTUgeiIgZmlsbD0iI0ZGRiIvPjwvc3ZnPg==')
    no-repeat center;
}

.ril__navButtonNext {
  right: 0;
  background: rgba(0, 0, 0, 0.2)
    url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgd2lkdGg9IjIwIiBoZWlnaHQ9IjM0Ij48cGF0aCBkPSJtIDEsMyAyLC0yIDE2LDE2IC0xNiwxNiAtMSwtMSAxNSwtMTUgLTE1LC0xNSB6IiBmaWxsPSIjRkZGIi8+PC9zdmc+')
    no-repeat center;
}

.ril__downloadBlocker {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url('data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7');
  background-size: cover;
}

.ril__caption,
.ril__toolbar {
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
}

.ril__caption {
  bottom: 0;
  max-height: 150px;
  overflow: auto;
}

.ril__captionContent {
  padding: 10px 20px;
  color: #fff;
}

.ril__toolbar {
  top: 0;
  height: 50px;
}

.ril__toolbarSide {
  height: 50px;
  margin: 0;
}

.ril__toolbarLeftSide {
  padding-left: 20px;
  padding-right: 0;
  flex: 0 1 auto;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ril__toolbarRightSide {
  padding-left: 0;
  padding-right: 20px;
  flex: 0 0 auto;
}

.ril__toolbarItem {
  display: inline-block;
  line-height: 50px;
  padding: 0;
  color: #fff;
  font-size: 120%;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ril__toolbarItemChild {
  vertical-align: middle;
}

.ril__builtinButton {
  width: 40px;
  height: 35px;
  cursor: pointer;
  border: none;
  opacity: 0.7;
}
.ril__builtinButton:hover {
  opacity: 1;
}
.ril__builtinButton:active {
  outline: none;
}

.ril__builtinButtonDisabled {
  cursor: default;
  opacity: 0.5;
}
.ril__builtinButtonDisabled:hover {
  opacity: 0.5;
}

.ril__closeButton {
  background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZlcnNpb249IjEuMSIgd2lkdGg9IjIwIiBoZWlnaHQ9IjIwIj48cGF0aCBkPSJtIDEsMyAxLjI1LC0xLjI1IDcuNSw3LjUgNy41LC03LjUgMS4yNSwxLjI1IC03LjUsNy41IDcuNSw3LjUgLTEuMjUsMS4yNSAtNy41LC03LjUgLTcuNSw3LjUgLTEuMjUsLTEuMjUgNy41LC03LjUgLTcuNSwtNy41IHoiIGZpbGw9IiNGRkYiLz48L3N2Zz4=')
    no-repeat center;
}

.ril__zoomInButton {
  background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCI+PGcgc3Ryb2tlPSIjZmZmIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCI+PHBhdGggZD0iTTEgMTlsNi02Ii8+PHBhdGggZD0iTTkgOGg2Ii8+PHBhdGggZD0iTTEyIDV2NiIvPjwvZz48Y2lyY2xlIGN4PSIxMiIgY3k9IjgiIHI9IjciIGZpbGw9Im5vbmUiIHN0cm9rZT0iI2ZmZiIgc3Ryb2tlLXdpZHRoPSIyIi8+PC9zdmc+')
    no-repeat center;
}

.ril__zoomOutButton {
  background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCI+PGcgc3Ryb2tlPSIjZmZmIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCI+PHBhdGggZD0iTTEgMTlsNi02Ii8+PHBhdGggZD0iTTkgOGg2Ii8+PC9nPjxjaXJjbGUgY3g9IjEyIiBjeT0iOCIgcj0iNyIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjZmZmIiBzdHJva2Utd2lkdGg9IjIiLz48L3N2Zz4=')
    no-repeat center;
}

.ril__outerAnimating {
  animation-name: closeWindow;
}

@keyframes pointFade {
  0%,
  19.999%,
  100% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
}

.ril__loadingCircle {
  width: 60px;
  height: 60px;
  position: relative;
}

.ril__loadingCirclePoint {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
.ril__loadingCirclePoint::before {
  content: '';
  display: block;
  margin: 0 auto;
  width: 11%;
  height: 30%;
  background-color: #fff;
  border-radius: 30%;
  animation: pointFade 800ms infinite ease-in-out both;
}
.ril__loadingCirclePoint:nth-of-type(1) {
  transform: rotate(0deg);
}
.ril__loadingCirclePoint:nth-of-type(7) {
  transform: rotate(180deg);
}
.ril__loadingCirclePoint:nth-of-type(1)::before,
.ril__loadingCirclePoint:nth-of-type(7)::before {
  animation-delay: -800ms;
}
.ril__loadingCirclePoint:nth-of-type(2) {
  transform: rotate(30deg);
}
.ril__loadingCirclePoint:nth-of-type(8) {
  transform: rotate(210deg);
}
.ril__loadingCirclePoint:nth-of-type(2)::before,
.ril__loadingCirclePoint:nth-of-type(8)::before {
  animation-delay: -666ms;
}
.ril__loadingCirclePoint:nth-of-type(3) {
  transform: rotate(60deg);
}
.ril__loadingCirclePoint:nth-of-type(9) {
  transform: rotate(240deg);
}
.ril__loadingCirclePoint:nth-of-type(3)::before,
.ril__loadingCirclePoint:nth-of-type(9)::before {
  animation-delay: -533ms;
}
.ril__loadingCirclePoint:nth-of-type(4) {
  transform: rotate(90deg);
}
.ril__loadingCirclePoint:nth-of-type(10) {
  transform: rotate(270deg);
}
.ril__loadingCirclePoint:nth-of-type(4)::before,
.ril__loadingCirclePoint:nth-of-type(10)::before {
  animation-delay: -400ms;
}
.ril__loadingCirclePoint:nth-of-type(5) {
  transform: rotate(120deg);
}
.ril__loadingCirclePoint:nth-of-type(11) {
  transform: rotate(300deg);
}
.ril__loadingCirclePoint:nth-of-type(5)::before,
.ril__loadingCirclePoint:nth-of-type(11)::before {
  animation-delay: -266ms;
}
.ril__loadingCirclePoint:nth-of-type(6) {
  transform: rotate(150deg);
}
.ril__loadingCirclePoint:nth-of-type(12) {
  transform: rotate(330deg);
}
.ril__loadingCirclePoint:nth-of-type(6)::before,
.ril__loadingCirclePoint:nth-of-type(12)::before {
  animation-delay: -133ms;
}
.ril__loadingCirclePoint:nth-of-type(7) {
  transform: rotate(180deg);
}
.ril__loadingCirclePoint:nth-of-type(13) {
  transform: rotate(360deg);
}
.ril__loadingCirclePoint:nth-of-type(7)::before,
.ril__loadingCirclePoint:nth-of-type(13)::before {
  animation-delay: 0ms;
}

.ril__loadingContainer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.ril__imagePrev .ril__loadingContainer,
.ril__imageNext .ril__loadingContainer {
  display: none;
}

.ril__errorContainer {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}
.ril__imagePrev .ril__errorContainer,
.ril__imageNext .ril__errorContainer {
  display: none;
}

.ril__loadingContainer__icon {
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.fslightbox-toolbar-button:nth-child(1),
.fslightbox-toolbar-button:nth-child(2) {
  display: none;
}

.fslightbox-slide-btn {
  border-radius: 100%;
}
.ril__navButtons {
  z-index: 9999;
}

.ril__navButtons.ril-next-button,
.ril__navButtons.ril-prev-button {
  width: 50px;
  height: 50px;
  padding:0;
  background: red;
  outline:none;
  border-radius: 100%;
}

.ril__navButtons.ril__navButtonNext {
  right: 20px;
background: rgba(255,255,255,0.6) url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='8px' height='14px' viewBox='0 0 8 14' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EPath%3C/title%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='angle-right-b-(1)' fill='%23000000' fill-rule='nonzero'%3E%3Cpath d='M7.54,6.29 L1.88,0.64 C1.6922334,0.450687349 1.43663754,0.344201676 1.17,0.344201676 C0.903362464,0.344201676 0.6477666,0.450687349 0.46,0.64 C0.0722764053,1.03003745 0.0722764053,1.65996255 0.46,2.05 L5.41,7.05 L0.46,12 C0.0722764053,12.3900375 0.0722764053,13.0199625 0.46,13.41 C0.647071436,13.6008556 0.902754104,13.7088906 1.17,13.71 C1.4372459,13.7088906 1.69292856,13.6008556 1.88,13.41 L7.54,7.76 C7.74521118,7.57068442 7.86193289,7.30419889 7.86193289,7.025 C7.86193289,6.74580111 7.74521118,6.47931558 7.54,6.29 Z' id='Path'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E") center center no-repeat;
}

.ril__navButtons.ril__navButtonPrev {
  left: 20px;
  background: rgba(255,255,255,0.6) url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='8px' height='14px' viewBox='0 0 8 14' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EPath%3C/title%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='angle-left-b-(1)' fill='%23000000' fill-rule='nonzero'%3E%3Cpath d='M0.5,7.8 L6.2,13.4 C6.6,13.8 7.2,13.8 7.6,13.4 C7.6,13.4 7.6,13.4 7.6,13.4 C8,13 8,12.4 7.6,12 L2.7,7 L7.6,2 C8,1.6 8,1 7.6,0.6 C7.4,0.4 7.2,0.3 6.9,0.3 C6.6,0.3 6.4,0.4 6.2,0.6 L0.5,6.2 C0.1,6.7 0.1,7.3 0.5,7.8 C0.5,7.7 0.5,7.7 0.5,7.8 Z' id='Path'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E") center center no-repeat;
}

.ril-outer.ril__outer {
  background-color: rgba(255,255,255,1);
}
.ril-toolbar.ril__toolbar {
  background-color: transparent;
}

.ril__closeButton.ril__builtinButton {
width:40px;
position: relative;
height:40px;
padding:20px;
margin:20px;
background:rgba(255,255,255,0.6);
border-radius: 100%;
outline:none;
}

.ril__closeButton.ril__builtinButton:after {
  content:'';
  font-family:'Gilroy';
  display:inline-block;
  padding:0;
  width:20px;
  height:20px;
  position: absolute;
  top:50%;
  left:50%;
  transform:translate(-50%,-50%);
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23000000' d='M13.41,12l6.3-6.29a1,1,0,1,0-1.42-1.42L12,10.59,5.71,4.29A1,1,0,0,0,4.29,5.71L10.59,12l-6.3,6.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0L12, 13.41l6.29,6.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42Z'/%3E%3C/svg%3E");;
}

.ril__closeButton.ril__builtinButton:before {
  content:'Close';
  position: absolute;
  top:100%;
  left:50%;
  transform:translate(-50%,5px);
  font-family: Gilroy, sans-serif;
  font-size:16px;
  font-weight: bold;
  color: #ffffff;

}

.ril-inner.ril__inner {
  user-select: none;
  &:after {
    content: '';
    top:0;
    left:0;
    bottom:0;
    right:0;
    position:absolute;
  }
}

.ril__toolbarItem {
  line-height: 1 !important;
}

.ril-toolbar-right.ril__toolbarSide.ril__toolbarRightSide {
  margin:0;
  padding:0;
}

.narrate__container {
  &:hover {
    .narrate__controls {
      display:block;
    }
  }
}

.narrate__controls {
  display:none;
  z-index:9999;
}
.InputElement {
  font-family: 'Gilroy', sans-serif;
}