@mixin breakpoint($class)
{
    @if $class == tablet
    {
        @media (min-width: 736px) and (max-width: 1024px)
        {
            @content;
        }
    }

    @if $class == "tablet-landscape"
    {
        @media (min-width: 736px) and (max-width: 1024px) and (orientation: landscape)
        {
            @content;
        }
    }

    @else if $class == mobile
    {
        @media (min-width: 320px) and (max-width : 736px)
        {
            @content;
        }
    }

    @else if $class == desktop
    {
        @media (min-width: 1024px)
        {
            @content;
        }
    }
}
